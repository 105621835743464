import React from 'react';

function Error() {
  return (
    <div>
      <h3>Error: Page does not exist!</h3>
    </div>
  );
}

export default Error;
